import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

const Navbar = ({ auth, handleLogout }) => {
  const handleLinkClick = () => {
    const offcanvasToggle = document.getElementById("offcanvasNavbar");
    if (offcanvasToggle) {
      offcanvasToggle.classList.remove("show");
      const backdrop = document.querySelector(".offcanvas-backdrop");
      if (backdrop) {
        backdrop.classList.remove("show");
        backdrop.style.display = "none";
      }
    }
  };

  const handleToggleClick = () => {
    const offcanvasToggle = document.getElementById("offcanvasNavbar");
    if (offcanvasToggle) {
      offcanvasToggle.classList.toggle("show");
      const backdrop = document.querySelector(".offcanvas-backdrop");
      if (backdrop) {
        backdrop.classList.toggle("show");
        backdrop.style.display = backdrop.classList.contains("show")
          ? "block"
          : "none";
      }
    }
  };

  const handleClickOutside = (event) => {
    const offcanvasToggle = document.getElementById("offcanvasNavbar");
    if (
      offcanvasToggle &&
      !offcanvasToggle.contains(event.target) &&
      !event.target.closest(".navbar-toggler")
    ) {
      handleLinkClick();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <nav className="navbar navbar-expand-lg bg-light sticky-top">
      <div className="container-fluid">
        {/* Brand */}
        <Link className="navbar-brand" to="/">
          <img src="logo-2.png" alt="" className="logo" />
        </Link>

        {/* Offcanvas Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={handleToggleClick}
          aria-controls="offcanvasNavbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Offcanvas Content */}
        <div
          className="offcanvas offcanvas-end custom-offcanvas"
          tabIndex="-1"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
        >
          <div className="offcanvas-header">
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={handleLinkClick}
            ></button>
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
              {/* Links */}
              <li className="nav-item dropdown">
                <button
                  className="nav-link dropdown-toggle"
                  id="userMenu"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ border: "none", background: "transparent" }}
                >
                  <i className="bi bi-person-circle text-primary"></i>
                </button>
                <ul className="dropdown-menu" aria-labelledby="userMenu">
                  {!auth ? (
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/login"
                        onClick={handleLinkClick}
                      >
                        Login
                      </Link>
                    </li>
                  ) : (
                    <>
                      {/* <li>
                        <Link className="dropdown-item" to="/profile">
                          Profile
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/tenders-marked">
                          Tenders Marked
                        </Link>
                      </li> */}
                      {/* <li>
                        <hr className="dropdown-divider" />
                      </li> */}
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={handleLogout}
                        >
                          Logout
                        </button>
                      </li>
                    </>
                  )}
                </ul>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to="/"
                  onClick={handleLinkClick}
                >
                  <i className="bi bi-house-door-fill text-primary me-2"></i>
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/tenders"
                  onClick={handleLinkClick}
                >
                  <i className="bi bi-file-earmark-text-fill text-primary me-2"></i>
                  Tenders
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/subscriptions"
                  onClick={handleLinkClick}
                >
                  <i className="bi bi-credit-card-fill text-primary me-2"></i>
                  Subscriptions
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/analysis"
                  onClick={handleLinkClick}
                >
                  <i className="bi bi-bar-chart-fill text-primary me-2"></i>
                  Analysis
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
