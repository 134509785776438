import React, { useState, useEffect, lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import Cookies from "js-cookie";
import Layout from "../components/Layout/Layout";
import ErrorBoundary from "../components/ErrorBoundary/ErrorBoundary";
import PrivateRoute from "./PrivateRoute";
import "../styles.css";

// Lazy-loaded components
const HomePage = lazy(() => import("../components/Home/Home"));
const VyvsaiAi = lazy(() => import("../components/VyvsaiAi"));
const Login = lazy(() => import("../components/Login/Login"));
const Register = lazy(() => import("../components/Login/Register"));
const PasswordReset = lazy(() => import("../components/Login/PasswordReset"));
const RequestOtp = lazy(() => import("../components/Login/RequestOtp"));
const VerifyOtp = lazy(() => import("../components/Login/VerifyOtp"));
const Subscriptions = lazy(() => import("../components/Subscriptions"));
const Mission = lazy(() => import("../components/Mission/Mission"));
const Services = lazy(() => import("../components/Services/ServicesSection"));
const CoreValues = lazy(() => import("../components/CoreValues/CoreValues"));
const Reviews = lazy(() => import("../components/Reviews"));
const PrivacyPolicy = lazy(() => import("../components/Policy/PrivacyPolicy"));
const UploadDocuments = lazy(() => import("../components/UploadDocuments"));
const ContactUs = lazy(() => import("../components/ContactUs/ContactUs"));
const ProtectedComponent = lazy(() => import("./ProtectedComponent"));
const Tenders = lazy(() => import("../components/Tender/Tenders"));
const TenderDetail = lazy(() => import("../components/Tender/TenderDetail"));
const TenderResults = lazy(() => import("../components/Tender/TenderResults"));
const RefundPolicy = lazy(() => import("../components/Policy/RefundPolicy"));
const TermsAndConditions = lazy(() =>
  import("../components/Policy/TermsAndConditions")
);
const TenderFile = lazy(() => import("../components/Tender/TenderFile"));
const Payment = lazy(() => import("../components/Payment/Payment"));
const PaymentRedirect = lazy(() =>
  import("../components/Payment/PaymentStatus")
);
const PaymentSuccess = lazy(() =>
  import("../components/Payment/PaymentSuccess")
);
const PaymentFailure = lazy(() =>
  import("../components/Payment/PaymentFailure")
);
const Analysis = lazy(() =>
  import("../components/CompetitiveAnalysis/Analysis")
);
const SearchBar = lazy(() =>
  import("../components/CompetitiveAnalysis/SearchBar")
);
const CompetitorList = lazy(() =>
  import("../components/CompetitiveAnalysis/CompetitorList")
);

function App() {
  return (
    <Router>
      <ErrorBoundary>
        <AppWithRouter />
      </ErrorBoundary>
    </Router>
  );
}

function AppWithRouter() {
  const [auth, setAuth] = useState(Cookies.get("auth") === "true");
  const navigate = useNavigate();

  useEffect(() => {
    Cookies.set("auth", auth.toString());
  }, [auth]);

  const handleLogout = () => {
    Cookies.remove("token");
    setAuth(false);
    navigate("/");
  };

  return (
    <Layout auth={auth} handleLogout={handleLogout}>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<Login setAuth={setAuth} />} />
          <Route path="/register" element={<Register />} />
          <Route path="/password-reset" element={<PasswordReset />} />
          <Route path="/password-reset/request-otp" element={<RequestOtp />} />
          <Route path="/password-reset/verify-otp" element={<VerifyOtp />} />
          <Route path="/subscriptions" element={<Subscriptions />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/payment-status" element={<PaymentRedirect />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/payment-failure" element={<PaymentFailure />} />
          <Route path="/mission" element={<Mission />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/core-values" element={<CoreValues />} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          {/* Tender Routes */}
          <Route path="/tenders" element={<Tenders />} />
          <Route path="/tender-results" element={<TenderResults />} />
          <Route path="/tender-detail" element={<TenderDetail />} />
          <Route path="/tender-file" element={<TenderFile />} />
          {/* New Analysis-Related Routes */}
          <Route path="/analysis" element={<SearchBar />} />
          <Route path="/analysis/:contractorName" element={<Analysis />} />
          <Route path="/competitor-list" element={<CompetitorList />} />
          {/* Upload Documents */}
          <Route path="/upload-documents" element={<UploadDocuments />} />
          <Route path="/vyvsai-ai" element={<VyvsaiAi />} />
          {/* Protected Routes */}
          <Route
            path="/protected"
            element={
              <PrivateRoute auth={auth}>
                <ProtectedComponent />
              </PrivateRoute>
            }
          />
        </Routes>
      </Suspense>
    </Layout>
  );
}

export default App;
